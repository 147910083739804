import {SeatingPlan} from '@wix/ambassador-seating-plans/types'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {getEventId} from '@wix/wix-events-commons-statics'
import {ThunkConfig} from './interfaces'

export const getConnectedSeatingPlan = createAsyncThunk<SeatingPlan, void, ThunkConfig>(
  'GET_CONNECTED_SEATING_PLAN',
  async (_, {getState, extra: {serverApi}}) => {
    const eventId = getEventId(getState().event)
    return serverApi.getConnectedSeatingPlan(eventId)
  },
)
