import {AnyAction} from 'redux'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case selectTicket.toString():
    case unselectTicket.toString():
      if (selectTicket.match(action) || unselectTicket.match(action)) {
        return {...state, [action.payload.ticketId]: action.payload.count}
      }
      break
    default:
      return state
  }
}
