import {getEventId} from '@wix/wix-events-commons-statics'
import {isEditor, isMobile} from '../../../commons/selectors/environment'
import {isTemplate} from '../../../commons/selectors/instance'
import {openDialogModal, openModal} from '../../../commons/services/modal'
import {getReservationId} from '../selectors/reservation'
import {GetState, StoreExtraArgs} from '../types'
import * as constants from '../components/modals/constants'
import {navigateBack, navigateToDetails, navigateToTicketsPicker} from './navigation'
import {cancelReservation} from './reservation'
import {changeEvent} from './event'
import {getConnectedSeatingPlan} from './seating'

export const openCheckoutUnavailable = () => (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) =>
  dispatch(
    openDialogModal({
      type: 'checkout-unavailable',
      onClose: () => wixCodeApi.location.to(wixCodeApi.location.url),
    }),
  )

export const openTicketsDetailsModal = () => openNativeModal({modalType: constants.ORDER_SUMMARY})

export const openTicketsDownloadModal = () => openNativeModal({modalType: constants.TICKETS_DOWNLOAD})

export const openSeatingPlan = () => async (dispatch: Function) => {
  await dispatch(getConnectedSeatingPlan())
  dispatch(openNativeModal({modalType: constants.SEATING_PLAN}))
}

export const openTimeExpiredModal = () => (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isTemplate(state) || isEditor(state)) {
    return null
  }

  return dispatch(
    openDialogModal({
      type: 'time-expired',
      onClose: () => dispatch(onCloseTimeExpiredModal()),
    }),
  )
}

const onCloseTimeExpiredModal = () => (dispatch: Function, getState: GetState, extra: StoreExtraArgs) => {
  const state = getState()
  const eventId = getEventId(state.event)
  const reservationId = getReservationId(state)

  dispatch(cancelReservation(eventId, reservationId))

  if (extra.wixCodeApi.location?.query?.reservationId) {
    return dispatch(navigateBack())
  }

  if (isMobile(state)) {
    return dispatch(navigateToTicketsPicker())
  }

  return dispatch(navigateToDetails())
}

export const openCantCompletePaymentModal = () =>
  openDialogModal({
    type: 'cant-complete-payment',
  })

export const openRecurringEventsModal = (eventId: string) => (dispatch: Function) => {
  dispatch(openModal({type: 'recurring-events', params: {eventId}})).then((response: any) => {
    if (response.message.slug) {
      dispatch(changeEvent(response.message.slug))
    }
  })
}

export const OPEN_NATIVE_MODAL = 'OPEN_NATIVE_MODAL'

export const openNativeModal = ({modalType}: any) => ({
  type: OPEN_NATIVE_MODAL,
  payload: {
    modalType,
  },
})

export const CLOSE_NATIVE_MODAL = 'CLOSE_NATIVE_MODAL'

export const closeNativeModal = () => ({
  type: CLOSE_NATIVE_MODAL,
})
